@import "../../variable.scss";

.thumb_initial {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
    background: transparent;
    color: $hit_gray;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 24px;
        color: $hit_gray;
    }
    img {
        width: 100%;
    }
    &.no_data {
        background: transparent;
    }
    &.chat_area {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.chat_history_box {
    width: 100%;
    padding-right: 20px;
    height: calc(100vh - 20px)!important;
    overflow-y: auto;
    float: left;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        border-radius: 10px;
        background: $primary_border_color;
        margin-right: 5px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $hit_gray;
        box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

    .message {
        width: 100%;
        margin-bottom: 20px;
        .no_audit_found {
            font-size: 16px;
            color: $hit_gray;
            text-align: center;
        }
        .message_dt {
            color: $primary_color;
            text-align: center;
            width: 100%;
            float: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 700;
            .time {
                display: inline-block;
                font-weight: 400;
                font-size: 14px;
                padding-left: 3px;
            }
        }
        .divider {
            width: 100%;
            height: 1px;
            float: left;
            background: $primary_border_color;
            margin: 10px 0;
        }
        .chat_box {
            width: 100%;
            float: left;
            margin: 0 0 10px;
            padding: 0;
            position: relative;
            .chat {
                float: left;
                width: 100%;
                padding-left: 50px;
                margin: 0 0 10px;
                position: relative;
                .name {
                    width: 80%;
                    float: left;
                    white-space: normal;
                    font-weight: 700;
                    font-size: 14px !important;
                    color: $primary_color;
                    position: relative;
                    text-transform: capitalize;
                    .time {
                        display: inline-block;
                        font-weight: 400;
                    }
                    &.sm_name {
                        width: 100%;
                    }
                }
                .time_left {
                    width: 20%;
                    float: right;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: right;
                    font-size: 13px;
                    color: $hit_gray;
                    line-height: 22px;
                    &.sm_time_left {
                        width: auto;
                        float: none;
                        margin-left: 5px;
                        text-transform: lowercase;
                        font-weight: 400;
                    }
                }
                .message_txt {
                    width: 100%;
                    float: left;
                    color: $primary_color;
                    word-wrap: break-word;
                    white-space: pre-line;
                    font-size: 14px !important;
                }
            }
        }
    }
}
