
/* Basic colors */
$black : #000000;
$white: #ffffff;

/* Theme colors */
$primary_color : #364347;
$border_color: #b2c6cd;
$secondary_color: #02baf2;
$primary_border_color: #d6e1e5;
$pacific_blue: #0B95BF;
$success_color: #3eb5ac;
$danger_color: #f84545;
$danger_hover_color: #CC0000;

$shark : #1d2528;
$nevada: #5e696d;
$hit_gray: #93a4aa;
$hit_gray_a: #9daeb4;
$outer_space: #2F393C;
$concrete: #f3f3f3;
$black_squeeze: #E1EEF3;
$swans_down: #d8f0ee;
$keppel_a: #3db5ac;
$lochinvar: #28948c;
$tree_poppy: #f7941e;
$zest: #e08419;
$ecstasy: #F58410;
$gray_a: #999999;
$gray_b: #eeeeee;
$gray_c: #dddddd;
$gray_d: #f1f1f1;
$gray_e: #d4dfe3;
$gray_f: #888888;

